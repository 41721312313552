import React from 'react'

// React multi carousel
import Carousel from 'react-multi-carousel'
import ImgixFluidImage from "../../../ImgixFluidImage"
// import 'react-multi-carousel/lib/styles.css'

// Tailwind React UI
import { 
	Box,
	Card,
	CardBody,
	FillButton,
	Flex,
	Title
} from 'tailwind-react-ui'

import ArrowLeft from "../../../../images/icons/icon-arrow_left_green.svg"
import ArrowRight from "../../../../images/icons/icon-arrow_right_green.svg"

const YellowCarousel = ({items}) => {

	const CustomLeft = ({ onClick }) => (
		<button className="absolute right-full -mr-10 arrow left px-2 py-3 shadow z-10 top-1/4 -mt-8 bg-white" onMouseDown={onClick} aria-label="Previous slide">
			<ArrowLeft alt="Previous slide" />  
		</button>
	)
	const CustomRight = ({ onClick }) => (
		<button className="absolute left-full -ml-10 arrow right px-2 py-3 shadow z-10 top-1/4 -mt-8 bg-white" onMouseDown={onClick} aria-label="Next slide">
			<ArrowRight alt="Next slide" />  
		</button>
	)

	const responsive = {
		desktop: {
			breakpoint: {
				max: 2500,
				min: 1200
			},
			items: 1,
		},
		tablet: {
			breakpoint: {
				max: 1200,
				min: 600
			},
			items: 1,
		},
		mobile: {
			breakpoint: {
				max: 600,
				min: 0
			},
			items: 1,
		} 
	}

	const CustomDot = ({ onMove, index, onClick, active }) => {
		// onMove means if dragging or swiping in progress.
		// active is provided by this lib for checking if the item is active or not.
		if (index <= 6) {
			return (
				<li key={index} className={`transition duration-300 ease-in-out bg-white text-sm w-full text-center hover:text-white active:text-white mb-2 py-2 px-3 border focus:bg-secondary hover:bg-secondary active:bg-secondary hover:border-secondary focus:border-secondary active:border-secondary rounded-none cursor-pointer font-display ${active ? "active border-secondary bg-secondary text-white" : "inactive"}`} >
					<button className="outline-none uppercase" onMouseDown={() => onClick()}>{items[index].heading}</button>
				</li>	
			)
		}
		return null
	}

	return (
		<>
			<Carousel
				arrows
				autoPlay	= {false}
				centerMode	= {false}
				containerClass	 = {`relative lg:-mx-3 yellowSlider`}
				customDot		 = {<CustomDot />}
				customLeftArrow	 = {<CustomLeft />}
				customRightArrow = {<CustomRight />}
				data-component-type="yellow-carousel"
				dotListClass	 = "top-1/2 xl:mt-3 hidden bg-white lg:flex flex-wrap justify-end right-14 bottom-auto ml-auto lg:w-1/4 yellowSlider"
				draggable	  = {false}
				focusOnSelect = {false}
				infinite
				itemClass = ""
				keyBoardControl
				minimumTouchDrag		 = {150}
				renderButtonGroupOutside = {false}
				renderDotsOutside		 = {false}
				responsive = {responsive}
				showDots
				sliderClass		= "py-5 sm:py-10"
				slidesToSlide	= {1}
				swipeable 
			>
				{items.map((card, i) => (
					<Card key={i} shadow className="card rounded-none flex flex-col sm:h-full mx-3 shadow-md border relative" p="0">
						<Box w="full" className="w-full mb-2 border-gray-300 overflow-hidden">
							<ImgixFluidImage className="hidden lg:block w-full bg-gray-light" alt={card.image[0].imageAlt ?? card.image[0].title ?? card.image[0].filename.replace(/[^a-z0-9+]+/gi, ' ')} imageUrl={card.image[0].url} ar={3.25} focalPoint={card.image[0].focalPoint} /> 
							<ImgixFluidImage className="block lg:hidden w-full bg-gray-light" alt={card.image[0].imageAlt ?? card.image[0].title ?? card.image[0].filename.replace(/[^a-z0-9+]+/gi, ' ')} imageUrl={card.image[0].url} ar={2.75} focalPoint={card.image[0].focalPoint} /> 
						</Box>
						<CardBody className="px-8 pt-6 pb-0 lg:w-2/3" style={{minHeight: '210px'}}>
							<Flex >
								<Box p="3" className="p-3">
									<Title level={3} font="heading" className="font-heading text-black text-lg break-all">{card.heading}</Title>
									<div data-component-type="rich-text" dangerouslySetInnerHTML={{__html: card.text}} />
								</Box>
							</Flex>
						</CardBody>
						<Flex wrap className="flex-wrap mt-auto text-left px-8 pb-4 flex-baseline">
							{card.relatedEntry[0] || card.buttonUrl ? <FillButton font="display" data-type="button" bg="white" bg-hocus="white" 
							text="primary" text-hocus="white"
							aria-label={`Click this button to find out more about ${card.heading}`}
							is="a" href={card.relatedEntry[0] ? '/' + card.relatedEntry[0].uri : card.buttonUrl} 
							className={`text-primary focus:text-white hover:text-white focus:bg-white hover:bg-white btn m-3 px-3 lg:px-8 py-3 rounded-full border-primary focus:border-primary hover:border-primary`}><span className="block relative">{card.buttonText ?? 'Find out more'}
							</span></FillButton>: '' }
						</Flex>
					</Card>
				))}
			</Carousel>
		</>
		
	)
}

export default YellowCarousel