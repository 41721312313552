import React from "react";
import { graphql } from "gatsby";

// Layout
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Matrix from "../components/Matrix";
import { buildFluidImageData } from "@imgix/gatsby";

// Tailwind React UI Components
import { Container, Section, Title, Text } from "tailwind-react-ui";

// Custom Components
import SubPageBanner from "../components/SubPageBanner";
import SecondaryNav from "../components/SecondaryNav";

import YellowCarousel from "../components/Matrix/types/Independent/YellowCarousel";
import TriangleGY from "../images/patterns/patterns-triangles_greenyellow.svg";
import Journey from "../components/Matrix/types/Independent/Journey";
import CableCar from "../images/patterns/pattern-arrow_cablecar.svg";
import QuickTips from "../components/Matrix/types/Independent/QuickTips";
import Accordion from "../components/Matrix/types/BodyContent/Accordion";
import Heading from "../components/Matrix/types/BodyContent/Heading";
import SignUpBanner from "../components/Matrix/types/Independent/SignUpBanner";

// GraphQL
export const query = graphql`
  query PlanYourVisitPageAutoQuery($slug: String!) {
    landingPage: craftPlanYourVisitPlanYourVisitEntry {
      journey {
        ... on Craft_journey_step_BlockType {
          id
          heading
          image {
            ...UploadAssetFragment
          }
          icon {
            url
          }
          iconBackgroundColour
          text
        }
      }
      bodyContent {
        ...BodyContentFragment
      }
      additionalText
      yellowContentSlider {
        ... on Craft_yellowContentSlider_slide_BlockType {
          heading
          image {
            ...UploadAssetFragment
          }
          text
          buttonText
          buttonUrl
          relatedEntry {
            uri
          }
        }
      }
      quickTips {
        ...QuickTipsFragment
      }
      heading {
        ... on Craft_heading_heading_BlockType {
          id
          text
        }
      }
      signUpBanner {
        ...SignUpBannerFragment
      }
      accordion {
        ... on Craft_accordion_accordionRow_BlockType {
          id
          heading
          text
        }
      }
    }
    entry: craftPlanYourVisitPagesPlanYourVisitEntry(slug: { eq: $slug }) {
      id
      uri
      url
      title
      remoteId
      typeHandle
      subtitle
      postDate
      featureImage {
        ...UploadAssetFragment
      }
      metaTitle
      metaDescription
      metaKeywords
      metaImage {
        ...UploadAssetFragment
      }
      bodyContent {
        ...BodyContentFragment
      }
    }
    siteInfo: craftSiteInformationGlobalSet {
      ...SiteInfoFragment
    }
    navigation: allCraftMainNavigationRelatedEntryEntry(
      filter: { relatedEntry: { elemMatch: { sectionId: { eq: 11 } } } }
    ) {
      group(field: relatedEntry___sectionId) {
        nodes {
          relatedEntry {
            title
            uri
          }
        }
      }
    }
  }
`;

const truncateString = (string = "", maxLength = 54) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

const PlanYourVisit = ({
  data: { entry, siteInfo, navigation, landingPage },
}) => {
  // secondary navigation
  const secondaryNav = navigation.group[0].nodes;

  const subtitle = entry.subtitle || siteInfo.siteTitle;
  const [siteImage] = siteInfo.siteImage;
  const [featureImage = siteImage] = entry.featureImage;
  const metaImage = featureImage || entry.metaImage || siteInfo.siteImage;
  const metaDescription = truncateString(
    (
      entry.metaDescription ||
      entry.summary ||
      siteInfo.siteDescription
    ).replace(/(<([^>]+)>)/gi, ""),
    159
  );
  const metaTitle = truncateString(
    `${entry.metaTitle || entry.displayTitle || entry.title}`,
    53
  );
  const metaKeywords = entry.metaKeywords || siteInfo.siteKeywords || null;

  const metaImageFormatted = buildFluidImageData(`${metaImage.url}`, {
    ar: 1, // required
    auto: ["format", "compress"],
    sizes: `300px, 300px`, // optional, but highly recommended
    width: 300,
    height: 300,
  });

  return (
    <Layout location={entry.url} title={metaTitle}>
      <Seo
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywords}
        image={metaImageFormatted.src}
        url={entry.url}
        pathname={`/${entry.uri}`}
      />
      <h1 style={{ fontSize: 0, color: "transparent" }} className="sr-only">
        {metaTitle}
      </h1>

      {featureImage && (
        <Section p="0" className="relative">
          <SubPageBanner
            title={entry.title}
            subtitle={subtitle}
            image={featureImage}
          />
        </Section>
      )}

      <SecondaryNav items={secondaryNav} />

      {entry.uri === "plan-your-visit/fast-track" && (
        <>
          {landingPage.additionalText && (
            <Section id="fastTrack" bg="white" className="relative" p="0">
              <Container className="py-14 lg:pt-20 w-full xl:w-3/5">
                <Title
                  level={3}
                  text="primary"
                  font="heading"
                  className="text-primary font-heading lg:text-5xl relative flex justify-center flex-start"
                >
                  Fast Track Additions
                  <TriangleGY className="ml-6 -mt-1" alt="Triangles" />
                </Title>
                <div
                  className="py-4 secondary-page"
                  dangerouslySetInnerHTML={{
                    __html: landingPage.additionalText,
                  }}
                />
              </Container>
            </Section>
          )}

          {landingPage.accordion.length > 0 && (
            <Section
              id="faqs"
              bg="blue-dark"
              className="bg-blue-dark relative mb-8"
              p="0"
            >
              <Container className="xl:w-3/4 pt-20 pb-24">
                <Heading
                  block={{
                    hierarchy: 2,
                    align: "center",
                    text: landingPage.heading[0].text,
                    colour: "white",
                  }}
                  margins="mb-14"
                />
                {landingPage.accordion.map((block, i) => (
                  <Accordion
                    key={`acc${i}`}
                    arrow="#e5e7eb"
                    theme="white"
                    block={block}
                  />
                ))}
              </Container>
            </Section>
          )}

          <Section>
            <Container>
              <SignUpBanner block={landingPage.signUpBanner[0]} />
            </Container>
          </Section>
        </>
      )}

      {entry.uri === "plan-your-visit/specials" && (
        <Section id="specials" p="0">
          <Container className="pt-14 pb-8 w-full xl:w-3/4">
            <Title
              level={3}
              text="primary"
              font="heading"
              className="font-heading text-primary lg:text-5xl relative flex justify-center flex-start"
            >
              Our Specials
              <TriangleGY className="ml-6 -mt-1" alt="Triangles" />
            </Title>
            {landingPage.yellowContentSlider ? (
              <YellowCarousel items={landingPage.yellowContentSlider} />
            ) : null}
          </Container>
        </Section>
      )}

      {entry.bodyContent.length && (
        <Section p="0" className="secondary-page">
          <Container className="xl:w-2/3 pb-18 pt-16">
            <Matrix blocks={entry.bodyContent} />
          </Container>
        </Section>
      )}

      {entry.uri === "plan-your-visit/getting-here" && (
        <>
          {landingPage.bodyContent.length && (
            <Section
              className="secondary-page relative bg-gray-light p-0"
              p="0"
            >
              <Container className="pt-14 pb-8 lg:pt-20 lg:pb-14 w-full xl:w-3/4">
                <Matrix blocks={landingPage.bodyContent} />
              </Container>
            </Section>
          )}

          <Section id="tips" p="0" className="p-0">
            <Container className="pt-14 pb-8 lg:pt-20 lg:pb-14 w-full xl:w-3/4">
              <Title
                level={4}
                text="primary"
                font="heading"
                className="text-primary font-heading lg:text-5xl relative flex justify-center flex-start mb-5"
              >
                Quick Tips
                <TriangleGY className="ml-6 -mt-1" alt="Triangles" />
              </Title>
              <Text is="p" className="text-center text-md">
                Important info to know before you go. Got a question for us?
                Give us a call on (021) 424 8181, tweet us @tablemountainca,
                post on our Facebook wall or email us on info@tablemountain.net.
              </Text>

              {landingPage.quickTips ? (
                <>
                  <hr className="border-0 block my-14" />
                  <div className="shadow-md p-6 sm:p-10 bg-white rounded-lg">
                    <QuickTips block={landingPage.quickTips} />
                  </div>
                </>
              ) : null}
            </Container>
          </Section>

          <Section id="journey" p="0" className="p-0">
            <Container className="pt-14 pb-0 lg:py-20">
              <Title
                level={2}
                font="heading"
                className="font-heading text-center md:text-3xl lg:text-5xl relative flex justify-center flex-start"
              >
                {landingPage.displayTitle}
                <CableCar className="ml-1 -mr-5 -mt-1" alt="Cable Car icon" />
              </Title>
              <Journey block={landingPage.journey} />
            </Container>
          </Section>
        </>
      )}
    </Layout>
  );
};

export default PlanYourVisit;
